import { useIsFetching, useQueryClient } from "react-query";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Button, FormControl, InputLabel, Select, TextField, useTheme } from "@material-ui/core";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { useLocations, useModuleInventory } from "../../../intake/intakeQueries";
import { ModuleInventoryTablePage } from "./ModuleInventoryTablePage";
import React from "react";
import Pagination from "@material-ui/lab/Pagination"; // Make sure to import Pagination
import { useDispositions, useProjectDispositions, useWorkOrderDispositions, useWorkOrderUnitsDispositions } from "../../../common/services/dispositionServices";
import { useHistory } from "react-router-dom";

export const ModuleInventoryPage = () => {
  const [serialNumber, setSerialNumber] = React.useState("");
  const [page, setPage] = React.useState(1); // State for current page
  const itemsPerPage = 100; // Number of items per page
  const isFetching = useIsFetching();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const history = useHistory();


  const {
    data: data,
    error: errorData,
    isLoading: isLoading,
    isError: isErrorData,
  } = useModuleInventory(page == 1 ? 1 : (page - 1) * 100, itemsPerPage);



  const {
    error: errorLocations,
    data: locations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
  } = useLocations();


  const {
    error: errorDispositions,
    data: dispositions,
    isLoading: isLoadingDispositions,
    isError: isErrorDispositions,
  } = useWorkOrderUnitsDispositions();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value);
  };

  const handleButtonClick = () => {
    history.push(`/operations_management/module_inventory/${serialNumber}`);

  };

  // Handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    queryClient.invalidateQueries("module_inventory_data");

  };

  if (isLoading || isLoadingLocations || isLoadingDispositions) {
    return (
      <Backdrop open={isLoading || isLoadingLocations || isLoadingDispositions}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isErrorData) return <>{isErrorData && <ErrorMessage error={isErrorData} />}</>;

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1} >
        <Grid item xs={2}>
           <Pagination
            count={Math.ceil(data.count / itemsPerPage)}
            page={page}
            onChange={(event, value) => {
              console.log('Changing page to:', value);
              setPage(value);
              queryClient.invalidateQueries(['module_inventory_data', value]);
            }}
            variant="outlined"
            color="primary"
          />
        </Grid>
        <Grid  item xs={10}    alignItems="center" justifyContent="center">
          <FormControl >
            <TextField
              label="Serial number"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
         
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '16px', marginTop:'10px' }}
            onClick={handleButtonClick}>
            Search
            
          </Button>
        </Grid>

        <Grid item xs={1}>
         
        </Grid>
      </Grid>
      <Box padding={1}></Box>
      {data && locations && (
        <>
          {<ModuleInventoryTablePage data={data.results} locations={locations} dispositions={dispositions} />}
          <Box padding={2}> </Box>
          <Pagination
            count={Math.ceil(data.count / itemsPerPage)}
            page={page}
            onChange={(event, value) => {
              console.log('Changing page to:', value);
              setPage(value);
              queryClient.invalidateQueries(['module_inventory_data', value]);
            }}
            variant="outlined"
            color="primary"
          />
        </>
      )}
    </>
  );
};

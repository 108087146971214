import React from "react";
import { FormControlLabel, Checkbox, Button } from "@material-ui/core/";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { ErrorMessage } from "../../../common/ErrorMessage";

import { useActiveProjects } from "../../projectQueries";
import { ActiveProjectsTable } from "./activeProjectsTable";

import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { useFetchContext } from "../../../common/FetchContext";
import { urls } from "../../../common/urls";
import { useProjectDispositions } from "../../../common/services/dispositionServices";

export const ActiveProjectsPage = () => {
  const [archived, setArchived] = React.useState(false);
  
  const { authAxios } = useFetchContext();
  const getDownloads = async (): Promise<any> => {
    const response = await authAxios.get(urls.projects.active_projects_report_download,{
      responseType: 'blob'
    });

    const downloadUrl = URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${moment().format("MMM-DD-YYYY-HHMMSS")}`;
    document.body.appendChild(a);
    a.click();
  };


  const {
    error: errorDispositions,
    data: dispositions,
    isLoading: isLoadingDispositions,
    isError: isErrorDispositions,
  } = useProjectDispositions();


  const {
    data = [],
    error,
    isLoading,
    isError,
    isSuccess,
  } = useActiveProjects(archived);

  if (isLoading || isLoadingDispositions) {
    return (
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={6}>
          <Typography variant="h3"> Active Projects </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end" alignItems="flex-start">
        <Grid container spacing={2} alignItems="flex-start" justify="flex-end">
            <Grid item>
              <Button variant="contained" color="primary" onClick={getDownloads}>
                Download
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={archived}
                    onChange={() => {
                      setArchived(!archived);
                    }}
                    name="archived"
                  />
                }
                label="View Inactive"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isSuccess && <ActiveProjectsTable data={data} dispositions={dispositions}/>}
        </Grid>
      </Grid>
    </>
  );
  
              }  
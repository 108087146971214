import { Grid, LinearProgress, TableCell, TableRow } from "@material-ui/core";
import { useNoteRead } from "../../common/CommonMutations";
import { useFetchContext } from "../../common/FetchContext";
import { EgressSubTable } from "./EgressSubTable";
import { Label } from "@material-ui/icons";
import { useMyWorkorders } from "../../projectManagement/projectQueries";
import { useVerifyResultData } from "../dataEgressQueries";
import { ErrorMessage } from "../../common/ErrorMessage";
import { useDefaultColumn } from "../../../util/filter";

export const EgressSubTableMain = ({ id }: any) => {
    const defaultColumn = useDefaultColumn();
    const {
        data,
        error,
        isLoading,
        isError,
        isSuccess,
      } = useVerifyResultData(id);
    
      if (isLoading) {
        return (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        );
      }
    
      if (isError) {
        return <ErrorMessage error={error} />;
      }
    
      return (
        <>
        <EgressSubTable data={data} /> 
        </>
      );

}

import { Backdrop, CircularProgress, Paper, Typography } from "@material-ui/core";
import { CheckBoxOutlineBlankOutlined, CheckCircle, Error } from "@material-ui/icons";
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import React, { ReactNode } from "react";
import { useUnitLocationHistory } from "../../../intake/intakeQueries";

const HistoryTimeLine = ({serial_number, reloadHistory}:any) => {
  const {
    data: data,
    error: errorData,
    isLoading: isLoading,
    isError: isErrorData,
    isSuccess: isSuccess,
  } = useUnitLocationHistory(serial_number);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color="inherit" />
    </div>
    );
  }

  if(isErrorData){
    return(
      <>
        <Typography variant="h5" color="primary">No data Found</Typography>
      </>
    )
  }
  return (
    <Timeline align="alternate">
      {data&&data.map((item: {
        [x: string]: ReactNode; id: React.Key | null | undefined; flag: number; asset_id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; unit_id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; location: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; username: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; datetime: string | number | Date; }) => (
        <TimelineItem key={item.id}>
          <TimelineSeparator>
            {/* Conditional icon rendering based on the "flag" */}
            {/* {item.flag === 1 ? (
            ) : (
              <Error color="error" />
            )} */}
            <CheckCircle color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={{ padding: '6px 16px' }}>
              <Typography variant="h6" component="h1">
                {item.location_name}
              </Typography>
              {/* <Typography>{item.unit_id}</Typography> */}
              {/* <Typography>Location ID: {item.location}</Typography> */}
              <Typography>{item.username}</Typography>
              <Typography>{new Date(item.datetime).toLocaleString()}</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default HistoryTimeLine;

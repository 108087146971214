import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  MenuItem,
  Button,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  useTheme,
  Link,
  Divider,
  Card,
  Box,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  editProjectDetails,
  getLastEditProjectDetails,
  useGroups,
  useProjectDetails,
  useProjectManagers,
} from "../../projectManagementQueries";
import { useDispositions } from "../../../common/services/dispositionServices";
import { useCustomerDetailsContext } from "../../../common/CustomerDetailsContext";
import { useAuthContext } from "../../../common/AuthContext";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { ViewNote } from "../../../common/ViewNoteDialog";
import { processErrorOnMutation } from "../../../../util/errorMessaging";

import { useProjectNotes } from "../../projectQueries";
import { ViewAddNoteList } from "../../../common/ViewAddNotes";
import { stringSanitize } from "../../../common/util";
import { Messaging } from "../../../common/enums";
import { editUpdateProject, lastUpdateProject } from "../../projectManagementMutations";
import { result } from "lodash";
import { Label } from "@material-ui/icons";
import { formatDate } from "../../../common/formatDate";

export const LastEditedProjectItems =({id}: any) => {
 
  const theme = useTheme();


  const {
    error: errorProjectDetails,
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    isError: isErrorProjectDetails,
    isSuccess: isSuccessProjectDetails,
  } = getLastEditProjectDetails(id);



  if(isLoadingProjectDetails){
    return(
      <CircularProgress color="primary"/>
    )
  }else{
    return (
    
      <>
      
      {projectDetails?.project_details?.map((result : any)=>(

        
      <>
        <Box m={2} boxShadow={3}>
        <Card>
          <Box m={2}>
         <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4} >
              <Grid item xs={12}>
              <div>
              <Typography variant="subtitle1" color="secondary">
                Last modifed user       : {result.modified_by}
              </Typography>
              </div>
             
              <div>
              <Typography variant="subtitle1" color="secondary">
                Role          : {result.role}
              </Typography>
              </div>
              <div>
              <Typography variant="subtitle1" color="secondary">
                 Date : {formatDate(result.modified_on)}
              </Typography>
              </div>

              <div>
              <Typography variant="subtitle1" color="secondary">
                 Comments : {result.comments}
              </Typography>
              </div>
              
            </Grid>
        </Grid>
        </Box>
        </Card>
          </Box>
      
      </>
      ))}
      </>
    );
  }


 
};

import { useRouteMatch, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../../auth/PrivateRoute";
import { StressHistoricDetailsContextProvider as StressHistoricDetails } from "../../projectManagement/components/stressEntry/StressHistoricContext";

import Reports from "../../projectManagement/components/reports/Reports";
import { FailedProjectManagement } from "./FailedProjectManagement";

export const FailedProjectRoutes = () => {
  const { path } = useRouteMatch();
  console.log(path)

  return (
        <StressHistoricDetails>
          <Switch>
          <PrivateRoute
              exact
              path={`${path}`}
              component={FailedProjectManagement}
            />
          
          
          
          </Switch>
        </StressHistoricDetails>
  );
};
